<script setup lang="ts">
import type { NuxtLinkProps } from "#app";
import { KsIcon } from "@aschehoug/kloss";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons";

defineProps<{
  text: string;
  linkLabel: string;
  linkTo: NuxtLinkProps["to"];
}>();

const { public: env } = useRuntimeConfig();
</script>

<template>
  <NuxtLink :to="linkTo" class="group flex w-full flex-col gap-m rounded-md bg-seagreen-50 px-l py-m no-underline transition-colors duration-300 hover:bg-seagreen-40 sm:flex-row">
    <div class="flex flex-col justify-between gap-xs">
      <p class="font-headline text-2xl leading-9 text-white" v-text="text" />

      <div class="flex items-center gap-s py-xs text-base font-semibold leading-6 text-yellow-20 no-underline">
        {{ linkLabel }}

        <KsIcon :icon="faLongArrowRight" class="transition-transform duration-300 group-hover:translate-x-4" />
      </div>
    </div>

    <img
      alt=""
      :src="`${env.assetPrefix}/graphics/packet-shapes.svg`"
      class="translate-y-m px-[37px] xs:px-[130px]"
    />
  </NuxtLink>
</template>
